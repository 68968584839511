import {Select} from "@chakra-ui/react"
import {ethers} from 'ethers'
import React from 'react'
import "./App.css"
import MintButton from './assets/mint-button.gif' 
function App() {

  const [mintAmount, setMintAmount] = React.useState(1);

  const mint = async() => {
    //connect to the wallet//
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner()
    //contract instance//
    const contractAddr = '0xC4b3fD5dB1BcDeb8CB56E6530c52F2ae5265dAE0'
    const abi = ["function mint(uint256 _numberOfTokens) public payable"]
    const contract = new ethers.Contract(contractAddr, abi, signer)
    // make the call//
    console.log(mintAmount)
    console.log( ethers.utils.parseEther((0.069 * mintAmount).toString()))
    const network = await provider.getNetwork()
    if(network.chainId == 1) {
      const tx = await contract.mint(mintAmount, {value: ethers.utils.parseEther((0.069 * mintAmount).toString())})
      await tx.wait()
    }else {
      alert("Please switch to Ethereum Mainnet!")
    }

  }
  return (
    <div className="App">
      <img className="mint-button" onClick={mint} src={MintButton} alt='mint-button'/>
      <Select 
      className="mint-select"
      value={mintAmount}
      onChange={(e) => setMintAmount(e.target.value)}
      placeholder='1' 
      // size='lg' 
      width='335px' 
      height='60px'
      margin='auto' 
      bg='white'
      borderColor='white'
      color='black'
      borderRadius={0} 
      border='1px solid black !important'>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
      </Select>
    </div>
  );
}

export default App;
